import React from 'react'
import { Link } from 'react-router-dom'
import Drawer from '@mui/material/Drawer'

import useAppState from '../../services/appState'

import logo from './content/logo.png'
import linkArrow from './content/link-arrow.svg'
import menu from './content/menu.svg'
import cross from './content/cross.svg'

import './styles.scss'

const Header = () => {
  const mobileMenuOpened = useAppState(state => state.mobileMenuOpened)
  const setMobileMenuOpened = useAppState(state => state.setMobileMenuOpened)
  return (
    <div className="header-wrapper">
      <div className="header-logo">
        <Link to="/"><img src={logo} alt="Logo"/></Link>
      </div>
      <div className="header-links-wrapper">
        <a href="#" className="header-link">Token releases <img src={linkArrow} className="header-link-arrow"/></a>
        <a href="#" className="header-link">Apps <img src={linkArrow} className="header-link-arrow"/></a>
        <a href="#" className="header-link">Token sale</a>
        <a href="#" className="header-link">Roadmap</a>
        <a href="#" className="header-link">About us</a>
        <a href="#" className="header-link">F.A.Q.</a>
      </div>
      <div
        className="header-menu-btn-wrapper"
        onClick={() => { setMobileMenuOpened(!mobileMenuOpened) }}
      >
        <img src={menu} alt="Menu" />
      </div>
      <Drawer
        open={mobileMenuOpened}
        anchor="right"
        onClose={() => { setMobileMenuOpened(false) }}
      >
        <div className="mobile-menu">
          <div className="mobile-menu-header">
            <div className="mobile-menu-logo">
              <Link to="/"><img src={logo} alt="Logo"/></Link>
            </div>
            <div
              className="mobile-menu-btn-wrapper"
              onClick={() => { setMobileMenuOpened(!mobileMenuOpened) }}
            >
              <img src={cross} alt="Menu" />
            </div>
          </div>
          <div className="mobile-menu-links">
            <div className="mobile-menu-link"><a href="#">Token releases <img src={linkArrow} className="mobile-menu-link-arrow"/></a></div>
            <div className="mobile-menu-link"><a href="#">Apps <img src={linkArrow} className="mobile-menu-link-arrow"/></a></div>
            <div className="mobile-menu-link"><a href="#">Token sale</a></div>
            <div className="mobile-menu-link"><a href="#">Roadmap</a></div>
            <div className="mobile-menu-link"><a href="#">About us</a></div>
            <div className="mobile-menu-link"><a href="#">F.A.Q.</a></div>
          </div>
          <div className="mobile-menu-footer">
            <div className="mobile-menu-social">
              <div className="social-txt">Our social networks:</div>
              <div className="social-links">
                <a href="#" className="social-link"><img src='/content/instagram.svg' alt="Instagram" /></a>
                <a href="#" className="social-link"><img src='/content/twitter.svg' alt="Twitter" /></a>
                <a href="#" className="social-link"><img src='/content/telegram.svg' alt="Telegram" /></a>
                <a href="#" className="social-link"><img src='/content/reddit.svg' alt="Reddit" /></a>
                <a href="#" className="social-link"><img src='/content/facebook.svg' alt="Facebook" /></a>
                <a href="#" className="social-link"><img src='/content/instagram.svg' alt="YouTube" /></a>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default Header
