import React, { Suspense } from 'react'
import {
  Route,
  Switch,
  BrowserRouter as Router
} from 'react-router-dom'

import useAppState from '../../services/appState'

import './styles.scss'

import Header from '../header'
import Page from './page'
import Footer from '../footer'
const Home = React.lazy(() => import('../home'))

const App = () => {
  const mobileMenuOpened = useAppState(state => state.mobileMenuOpened)
  return (
    <div className={`app-root ${mobileMenuOpened && 'mobile-menu-opened'}`}>
      <Router>
        <Header/>
        <Switch>
          <Route exact path='/'>
            <Suspense fallback={<Page></Page>}><Page><Home /></Page></Suspense>
          </Route>
        </Switch>
        <Footer/>
      </Router>
    </div>
  )
}

export default App
