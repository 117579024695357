import React from 'react'

type Props = {
  children?: React.ReactChild
}

const Page: React.FC<Props> = ({ children }) => {
  return (
    <div className="page">
      {children}
    </div>
  )
}

export default Page
