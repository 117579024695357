import create from 'zustand'

interface IAppState {
  mobileMenuOpened: boolean,
  setMobileMenuOpened: (newValue: boolean) => void
}

const useAppState = create<IAppState>(set => ({
  mobileMenuOpened: false,
  setMobileMenuOpened: (newValue: boolean) => set({ mobileMenuOpened: newValue })
}))

export default useAppState
