import React from 'react'
import { Link } from 'react-router-dom'

import logo from './logo.png'

import './styles.scss'

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-top">
        <div className="footer-logo">
          <Link to="/"><img src={logo} alt="Logo"/></Link>
        </div>
        <div className="footer-links">
          <div className="footer-link"><a href="#">Token releases</a></div>
          <div className="footer-link"><a href="#">Roadmap</a></div>
          <div className="footer-link"><a href="#">About us</a></div>
          <div className="footer-link"><a href="#">Apps</a></div>
          <div className="footer-link"><a href="#">Token sale</a></div>
          <div className="footer-link"><a href="#">F.A.Q.</a></div>
        </div>
        <div className="footer-social">
          <div className="social-txt">Our social networks:</div>
          <div className="social-links">
            <a href="#" className="social-link"><img src='/content/instagram.svg' alt="Instagram" /></a>
            <a href="#" className="social-link"><img src='/content/twitter.svg' alt="Twitter" /></a>
            <a href="#" className="social-link"><img src='/content/telegram.svg' alt="Telegram" /></a>
            <a href="#" className="social-link"><img src='/content/reddit.svg' alt="Reddit" /></a>
            <a href="#" className="social-link"><img src='/content/facebook.svg' alt="Facebook" /></a>
            <a href="#" className="social-link"><img src='/content/instagram.svg' alt="YouTube" /></a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">© 2021 Libertytoken. All rights reserved</div>
    </div>
  )
}

export default Footer
